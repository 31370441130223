import FsLightbox  from 'fslightbox-react'
import React from 'react'
// import MultiActionBar from './multi-action-bar'
const fsGallery = ({sources,type,toggleEvent,data,slide=1,index = 0}) => {
    return (
        type === 'image' || type === 'blueprint' || type === 'tour' || type === 'video' ? <>
			<FsLightbox
			key={index}
			type={type}
			slide={slide}
			toggler={toggleEvent}
			sources={sources.map((element,index) => (
				<div key={index}>
					{type === 'image' || type === 'blueprint'
					? <img src={element.image} alt="imagen de galeria" />
					: <iframe className={"iframe-width"} allowfullscreen src={element.player_url} style={{width:"70vw",height:"80vh"}} frameborder="0" />}
					{/* <MultiActionBar float={true} active={type} data={data} /> */}
				</div>

			))}/>
        </>
        :'')
}

export default fsGallery