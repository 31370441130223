import * as React from "react"
import Layout from "../../../../components/v2/layout"
import Seo from "../../../../components/seo"
import { connect } from "react-redux"
import { useEffect } from "react"
import { getDevelopmentAction, getUnitsDevelopmentAction } from "../../../../redux/developmentsDucks"

//Components
import Main from '../../../../components/v2/Seleccion/Detail/main.jsx'
import About from '../../../../components/v2/Seleccion/Detail/about.jsx'
import Galery from '../../../../components/v2/Seleccion/Detail/galery.jsx'
import Grid from '../../../../components/v2/Seleccion/Detail/Grid.jsx'
import Video from '../../../../components/v2/Seleccion/Detail/video.jsx'
import Location from '../../../../components/v2/Seleccion/Detail/location.jsx'
import Contact from '../../../../components/v2/Contact.jsx'

const IndexPage = (props) => {
  

  const {id,dispatch,api_key,loading_unities,loading,development} = props

  const isSSR = typeof window === "undefined"

  useEffect(() => {
    if(api_key && id){
      dispatch(getDevelopmentAction(id))
      dispatch(getUnitsDevelopmentAction(id))
    }
  },[api_key,id])
  
  return (
  <article>
    <Layout development={development}>
      <Seo title="Creciendo junto al campo desde 1867" description="Más de 150 años de experiencia en el sector rural. Conozca los campos más destacados de la Argentina, servicio de tasación satelital, divisiones y consultoría."/>
        <Main />
        <About />
        <Galery />
        <Grid />
        <Video />
        <Location />
        <div id="contacto-scroll"></div>
        <Contact development={development} bigTitle={true} from="development" title={"Solicite una entrevista para recibir asesoramiento personalizado sobre esta propiedad."} />
    </Layout>
  </article>
)}

export default connect(state => ({
    development: state.developments.development,
    loading: state.developments.loading,
    loading_unities: state.developments.loading_unities,
    api_key: state.settings.keys.tokko
}),null)(IndexPage);