import React from "react"
import Loading from '../../Loading'
import { connect } from "react-redux"
import { getCover, getQuality } from "../../../../helpers/helper.rendering"
import { getName, getLocation } from "../../../../helpers/helper.developments"
const MainSeleccion = ({ development,loading }) => {

  function scrollTo(id) {
    const element = document.getElementById(id)
    const y =
      element.getBoundingClientRect().top + window.pageYOffset - 9 * 16 + 100
    window.scrollTo({ top: y, behavior: "smooth" })
  }

  return !loading ? (
    <section className="banner-top development d-flex align-items-center justify-content-center">
      <img src={getQuality(getCover(development.photos))} alt="Portada principal de un emprendimiento" />
      <div className="content white text-center">
        <h5 className={(!development?.custom_tags?.find(tag => tag.name.includes("Ha.")) ? 'd-none' : '')}>{development?.custom_tags?.find(tag => tag.name.includes("Ha."))?.name}</h5>
        <h1>{getName(development)}</h1>
        <h5 className="text-uppercase">{getLocation(development)}</h5>
      </div>
      <div className="position-absoute scroll-position">
        <div
          onClick={() => scrollTo("descripcion-scroll")}
          className="icon-scroll"
        ></div>
      </div>
    </section>
  ):<Loading absolute={true} />
}

export default connect(
  state => ({
    loading: state.developments.loading,
    development: state.developments.development,
  }),
  null
)(MainSeleccion)
