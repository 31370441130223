import React from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import mapStyles from './mapStyles.json';
import Icon from "../images/map-icon.png"
import { connect } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';
  
const defaultMapOptions = {
  styles: mapStyles
};

function changeFormat(value){
  if(value){
    var convert = value.toString()
    return convert.replace(',','.')
  }
}

const getMap = (props) => {
  
  const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              keys{
                google_map
              }
          }
    }`)


  const MyMapComponent = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
    defaultOptions={defaultMapOptions}
    defaultZoom={16}
    defaultCenter={{ lat: (props.latitud), lng: (props.longitud)}}    
  >
    {props.isMarkerShown && <Marker icon={{ url: Icon, scaledSize: new window.google.maps.Size(46,66)}} position={{ lat: (props.latitud), lng: (props.longitud) } }  />}
  </GoogleMap>

))

  return props.coordenadas && realEstate?.keys?.google_map !== '' ? (
    <div className="map">
      <MyMapComponent
        isMarkerShown
        latitud={parseFloat(changeFormat(props.coordenadas.geo_lat))}
        longitud={parseFloat(changeFormat(props.coordenadas.geo_long))} 
        googleMapURL={'https://maps.googleapis.com/maps/api/js?key=' + realEstate?.keys?.google_map}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        />
    </div>
  ): ''

}

export default connect(state => ({
  settings: state.settings,
}),null)(getMap);