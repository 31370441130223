import React from "react"
import { connect } from "react-redux"
import { getLastVideo, checkVideo } from "../../../../helpers/helper.properties"
const Video = ({ development }) => {
  return checkVideo(development) ? (
    <section className="video-seleccion-detail" id="video-scroll">
      <div className="container-fluid text-center">
        <h2>{getLastVideo(development).title}</h2>
        <div className="row justify-content-center mt-5 ">
            <div className="col-lg-10">
                <iframe className="w-100" allowfullscreen="1" src={getLastVideo(development).player_url ? getLastVideo(development).player_url + (getLastVideo(development).provider === 'youtube' ? "?fs=1&autoplay=0&rel=0&showinfo=0&modestbranding=1" : ''): ''} frameborder="0" ></iframe>
            </div>
        </div>
      </div>
    </section>
  ) : null
}

export default connect(
  state => ({
    loading: state.developments.loading,
    development: state.developments.development,
  }),
  null
)(Video)
