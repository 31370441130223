import React from "react"
import { connect } from "react-redux"
import {
  getFakeAddress,
  getLocation,
} from "../../../../helpers/helper.developments"
import Map from "../../../map"

const Location = ({ development }) => {
  return (
    <section className="location-seleccion-detail" id="ubicacion-scroll">
      <div className="container-fluid text-center">
        <div className="row justify-content-center">
          <div class="col-lg-12">
            <h2 class="section-name">Ubicación</h2>
            <h4 class="mb-lg-4 mb-4 pb-lg-0 pb-1">
              {getFakeAddress(development)}, {getLocation(development)}
            </h4>
          </div>
        </div>
      </div>
      <Map
        coordenadas={{
          geo_lat: development.geo_lat,
          geo_long: development.geo_long,
        }}
      />
    </section>
  )
}

export default connect(
  state => ({
    loading: state.developments.loading,
    development: state.developments.development,
  }),
  null
)(Location)
