import React, { useState } from "react"
import Gallery from "../../../FsLightboxGallery"

import { connect } from "react-redux"
import {
  getCover,
  getPhotos,
  getQuality,
} from "../../../../helpers/helper.rendering"
import {
  setCurrentlyImage,
  setTogglerImages,
} from "../../../../redux/propertiesDucks"
const Galery = ({ dispatch, development, currentlyImage, togglerImages }) => {
  const [toggler, setToggler] = useState()

  return development ? (
    <>
      <div className="galery-subsection overflow-hidden" id="galeria-scroll">
        {getPhotos(development).length > 0 ? (
          <Gallery
            index={1}
            sources={getPhotos(development)}
            slide={currentlyImage}
            type={"image"}
            toggleEvent={togglerImages}
            data={development}
          />
        ) : null}
      </div>
      <section className="galery-seleccion-detail d-flex align-items-center justify-content-between">
        {getPhotos(development).map(
          (photo, index) =>
            index < 2 && (
              <div
                className="content-image"
                data-aos={index === 0 ? "fade-right" : "fade-left"}
              >
                <img src={getQuality(photo)} alt="Imagen emprendimiento en una galeria" />
              </div>
            )
        )}
        <div
          onClick={() => dispatch(setTogglerImages())}
          className="btn btn-blured border white"
        >
          Ver más imagenes
        </div>
      </section>
    </>
  ) : (
    ""
  )
}

export default connect(
  state => ({
    loading: state.developments.loading,
    development: state.developments.development,
    currentlyImage: state.properties.currentlyImage,
    togglerImages: state.properties.togglerImages,
  }),
  null
)(Galery)
