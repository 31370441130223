import React from "react"
import { connect } from "react-redux"
import { getCover, getQuality } from "../../../../helpers/helper.rendering"
import {
  getName,
  getLocation,
  getDescription,
} from "../../../../helpers/helper.developments"
import Breadcrumbs from "../../breadcrumbs"

const TAGS = ["Ganadero", "Mixto", "Agrícola", "Forestal","Recreativo"]

const About = ({ development }) => {

  const findACustomTag = () => {
    const finder = []
    if(development.custom_tags){
      for (let index = 0; index < TAGS.length; index++) {
        const tag = TAGS[index];
        if(development.custom_tags.find(element => element.name.toLowerCase() === tag.toLowerCase())){
          finder.push(tag)
          // return true
        }
      }
    }
    return finder
  }

  return typeof window !== undefined ? (
    <section className="about-seleccion-detail" id="descripcion-scroll">
      <div className="container-fluid">
        <span className="text-center mb-5 pb-lg-5 d-flex justify-content-center">
          <Breadcrumbs
            props={[
              { name: "BULLRICH CAMPOS", route: "/", location: "" },
              { name: "Seleccion", route: "/seleccion", location: "" },
              { name: getName(development), route: "", location: "" },
            ]}
          />
        </span>
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="row">
              <div className={findACustomTag().length > 0 ? "col-lg-3 text-lg-start text-center mb-5 mb-lg-0" : 'd-none'}>
                <h2 className="text-center">Aptitud{findACustomTag().length > 1 && 'es'}</h2>
                <div className="d-lg-flex align-items-lg-end align-items-center justify-content-lg-start justify-content-center flex-wrap mt-3 mt-lg-5">
                  {development?.id &&
                    TAGS.map(
                      name =>
                        development.custom_tags.find(
                          element =>
                            element.name.toLowerCase() === name.toLowerCase()
                        ) && (
                          <div className="d-flex item-custom me-5 mb-4 mt-3 mt-lg-0 flex-column">
                            <img
                              src={"/" + name.toLowerCase() + ".svg"}
                              alt="SVG decorativo custom tag"
                            />
                            <h5 className="mt-2">{name}</h5>
                          </div>
                        )
                    )}
                </div>
                {/* {development?.id && console.log(TAGS.map((name) => development.custom_tags.find(element => element.name.toLowerCase() === name.toLowerCase())))} */}
              </div>
              <div className={(findACustomTag().length > 0 ? "col-lg-8" : "col-lg-10") + " offset-lg-1"}>
                <p>{getDescription(development)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  ):''
}

export default connect(
  state => ({
    loading: state.developments.loading,
    development: state.developments.development,
  }),
  null
)(About)
